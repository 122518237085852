import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MetaBallContainer } from '../MetaBallContainer';
import FadeInSection from '../FadeInSection';
import {
  Container,
  BannerWrapper,
  BannerBackground,
  SVG,
  BannerGrid,
  ButtonWrapper,
  SliderWrapper,
} from './styles';
import { ICorpCardCarousel } from './types';
import { CorporateButton } from '../CorporateButton';
import { CorpPressStoriesCardType } from '../CorpPressStoriesCard/types';
import { CorpPressStoriesCard } from '../CorpPressStoriesCard';
import { Text, Title } from '../Typography';
import clsx from 'clsx';
import { CorpCardCarouselVariant } from 'generated/graphql';

export const CorpCardCarousel = ({
  carouselData = {
    cardVariant: CorpCardCarouselVariant.neutral,
    title: '',
    super: '',
    type: 'story',
    color: 'peach',
    id: '',
    key: null,
    slidesToShow: 3.5,
  },
  cardData,
  settings,
  position,
  copyDictionary,
  id,
}: ICorpCardCarousel) => {
  id = String(Math.floor(Math.random() * (10000 - 0)));
  const componentName = `${id}-card-carousel`;
  const [leftShift, setLeftShift] = useState<number>(0);

  const cardShiftLeftHandler = () => {
    const cardWidth = document.querySelector('.carousel-card');
    const cardHalfWidth = (cardWidth as HTMLElement)?.offsetWidth / 2;
    setLeftShift(cardHalfWidth);
  };

  useEffect(() => {
    cardShiftLeftHandler();
    window.addEventListener('load', () => {
      cardShiftLeftHandler();
    });
    window.addEventListener('resize', () => {
      cardShiftLeftHandler();
    });
  });

  const onButtonClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'click_teaser_recipe_list',
      eventValues: {
        ec: 'teaser',
        ea: 'click',
        el: 'carousel_list',
        container_position: position,
        container_type: 'carousel',
      },
    });
  };

  const mask = (
    <svg
      viewBox="0 0 1440 473"
      height="100%"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      fill={carouselData?.color === 'peach' ? '#faf3ed' : '#e6e7ed'}
    >
      <path d="M0.5 425.631V47.369C0.638282 47.3493 0.809842 47.3249 1.01441 47.2959C1.64085 47.2072 2.57679 47.0755 3.81436 46.9037C6.28951 46.56 9.97118 46.056 14.7964 45.4144C24.4468 44.1313 38.6712 42.2981 56.9659 40.0983C93.5554 35.6986 146.425 29.8322 211.545 23.9657C341.785 12.2328 521.019 0.5 717 0.5C912.981 0.5 1093.72 12.2328 1225.46 23.9658C1291.33 29.8322 1344.95 35.6987 1382.1 40.0984C1400.67 42.2982 1415.13 44.1314 1424.95 45.4145C1429.85 46.0561 1433.6 46.5602 1436.12 46.9038C1437.38 47.0756 1438.33 47.2074 1438.97 47.2961C1439.18 47.3255 1439.36 47.3503 1439.5 47.3701V425.63C1439.36 425.65 1439.18 425.674 1438.97 425.704C1438.33 425.793 1437.38 425.924 1436.12 426.096C1433.6 426.44 1429.85 426.944 1424.95 427.585C1415.13 428.869 1400.67 430.702 1382.1 432.902C1344.95 437.301 1291.33 443.168 1225.46 449.034C1093.72 460.767 912.981 472.5 717 472.5C521.019 472.5 341.785 460.767 211.545 449.034C146.425 443.168 93.5554 437.301 56.9659 432.902C38.6712 430.702 24.4468 428.869 14.7964 427.586C9.97118 426.944 6.28951 426.44 3.81436 426.096C2.57679 425.925 1.64085 425.793 1.01441 425.704C0.809842 425.675 0.638282 425.651 0.5 425.631Z" />
    </svg>
  );

  const slides: CorpPressStoriesCardType[] | undefined = cardData;
  // avoid issues with last slide if there are only a few slides
  // if (cardData && cardData?.length < 5) {
  //   while (slides.length < 5) {
  //     slides.push(...cardData);
  //   }
  // } else if (cardData) {
  //   slides = [...cardData];
  // }
  const CardVariant = carouselData?.cardVariant ?? CorpCardCarouselVariant.neutral;

  const sliderSettings = {
    slidesToScroll: 1,
    slidesToShow: carouselData?.slidesToShow || 3.5,
    adaptiveHeight: false,
    infinite: false,
    arrows: true,
    dots: true,
    lazyLoad: 'ondemand',
    centerMode: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
    ],
    ...settings,
  };
  return (
    <Container data-test="card-carousel-top-level">
      <MetaBallContainer data-test="card-carousel-metaball-container">
        <FadeInSection delay="350ms">
          <BannerWrapper
            data-test="card-carousel-container"
            data-testid="CardCarousel"
            className={clsx(
              {
                'bg-corporate-offWhite': CardVariant === CorpCardCarouselVariant.primary,
                '': CardVariant === CorpCardCarouselVariant.neutral,
              },
              'max-w-none bg-center',
            )}
          >
            <BannerBackground
              data-test={[`${id}-background`]}
              className="absolute w-full h-full overflow-hidden"
            >
              <SVG data-test={[`${id}-desktop-svg`]}>{mask}</SVG>
            </BannerBackground>
            {carouselData && (
              <>
                <BannerGrid data-test={[`${id}-grid`]} className="my-15 md:py-10">
                  <Text tag="p" type="lead" className="text-center mb-2 mx-4">
                    {carouselData?.super}
                  </Text>
                  <Title tag="p" type="md" className="text-center mb-15 mx-4">
                    {carouselData?.title}
                  </Title>
                  <SliderWrapper
                    data-test="card-carousel-slider-wrapper"
                    leftShift={leftShift}
                    className="card-carousel"
                    parent="cardCarousel"
                  >
                    <Slider data-test="card-carousel-slider" {...sliderSettings}>
                      {slides?.map((item: CorpPressStoriesCardType, index: number) => (
                        <div
                          className="md:pr-5 sm:pr-0 carousel-card h-full"
                          key={componentName + index}
                        >
                          <CorpPressStoriesCard
                            type={carouselData?.type}
                            copyDictionary={copyDictionary}
                            data-test={`card-carousel-item-${item.id}`}
                            cardData={item}
                            cardVariant={CardVariant}
                          />
                        </div>
                      ))}
                    </Slider>
                  </SliderWrapper>
                  {carouselData?.buttonLink && (
                    <ButtonWrapper
                      data-test="card-carousel-cta-button-wrapper"
                      tag="div"
                      type="lead"
                    >
                      {/* Providing a link in the CMS will override the search link */}
                      <a
                        href={carouselData?.buttonLink?.url}
                        target="_self"
                        rel="noreferrer"
                        onClick={onButtonClick}
                      >
                        <span className="sr-only">{carouselData?.buttonLink?.displayName}</span>
                        <FadeInSection delay="100ms" bounce>
                          <CorporateButton
                            data-test="corp-card-carousel-cta-button"
                            label={carouselData?.buttonLink?.displayName}
                          />
                        </FadeInSection>
                      </a>
                    </ButtonWrapper>
                  )}
                </BannerGrid>
              </>
            )}
          </BannerWrapper>
        </FadeInSection>
      </MetaBallContainer>
    </Container>
  );
};
